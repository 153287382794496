import React, { useState } from "react";
import { minus, plus } from "../../assets/images";
import { motion, AnimatePresence } from "framer-motion";

interface Props {
  question: string;
  answer: string;
}

const FAQuestion = ({ question, answer }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => setIsOpen(!isOpen);

  return (
    <div className="question">
      <div className="question--header" onClick={toggleModal}>
        <p className="question--title">{question}</p>
        {isOpen ? (
          <div className="img--container" onClick={toggleModal}>
            <img src={minus} alt="close" />
          </div>
        ) : (
          <div className="img--container" onClick={toggleModal}>
            <img src={plus} alt="open" />
          </div>
        )}
      </div>
      <AnimatePresence mode="wait">
        {isOpen && (
          <motion.div
            initial={{
              height: 0,
              opacity: 0,
            }}
            animate={{
              height: "auto",
              opacity: 1,
              transition: {
                height: {
                  duration: 0.4,
                },
                opacity: {
                  duration: 0.25,
                  delay: 0.15,
                },
              },
            }}
            exit={{
              height: 0,
              opacity: 0,
              transition: {
                height: {
                  duration: 0.4,
                },
                opacity: {
                  duration: 0.25,
                },
              },
            }}
            key={answer}
            className="answer"
          >
            {answer}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default FAQuestion;
