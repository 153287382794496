import React from "react";
import { arrow, arrowRight } from "../../assets/images";
import LoadingComponent from "../LoadingComponent/LoadingComponent";

interface Props {
  text: any;
  icon: boolean;
  iconType2?: boolean;
  handleClick?: () => void;
  style?: any;
  btnType?: any;
  loadingState?: boolean;
}

const WaitListButton = ({
  icon,
  text,
  style,
  handleClick,
  iconType2,
  btnType,
  loadingState,
}: Props) => {
  return (
    <button
      type={btnType ? btnType : "button"}
      onClick={handleClick}
      style={style && style}
      className="wait--list--button"
    >
      {!loadingState ? (
        <>
          <p>{text}</p>
          {icon && <img src={iconType2 ? arrowRight : arrow} alt="arrow" />}
        </>
      ) : (
        <LoadingComponent />
      )}
    </button>
  );
};

export default WaitListButton;
