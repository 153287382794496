import React from "react";
import { check } from "../../assets/images";
import { BiErrorCircle } from "react-icons/bi";

interface Props {
  success?: string;
  error?: string;
}

const SuccessSubmission = ({ success, error }: Props) => {
  return (
    <div className="success--submission">
      {!error ? (
        <div className="img--container">
          <img src={check} alt="check" />
        </div>
      ) : (
        <BiErrorCircle className="error--icon" />
      )}

      {error ? (
        <p className="error-message">{error}</p>
      ) : (
        <p>
          We’ve added <span>{success}</span> to our waitlist. We’ll let you know
          when<span> 3kolo</span> is ready.
        </p>
      )}
    </div>
  );
};

export default SuccessSubmission;
