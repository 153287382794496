import React, { useState } from 'react';
import { facebook, instagram, linkedin, twitter } from '../../assets/images';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import FooterLinks from '../FooterLinks/FooterLinks';
import axios from 'axios';
import FooterLowerLinks from '../FooterLowerLinks/FooterLowerLinks';
import { Container } from 'react-bootstrap';
import Logo from '../Logo/Logo';

const Footer = () => {
  const [status, setStatus] = useState<any>({
    submitted: false,
    submitting: false,
    info: { error: false, msg: null },
  });
  const [inputs, setInputs] = useState({
    email: '',
  });
  const handleServerResponse = (ok: any, msg: any) => {
    if (ok) {
      setStatus({
        submitted: true,
        submitting: false,
        info: { error: false, msg: msg },
      });
      setInputs({
        email: '',
      });
    } else {
      setStatus({
        info: { error: true, msg: msg },
      });
    }
  };
  const handleOnChange = (e: any) => {
    e.persist();
    setInputs((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
    setStatus({
      submitted: false,
      submitting: false,
      info: { error: false, msg: null },
    });
  };
  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    setStatus((prevStatus: any) => ({ ...prevStatus, submitting: true }));
    axios({
      method: 'POST',
      url: 'https://formspree.io/f/xgebprko',
      data: inputs,
    })
      .then((response) => {
        handleServerResponse(
          true,
          'Thank you, your message has been submitted.'
        );
      })
      .catch((error) => {
        handleServerResponse(false, error.response.data.error);
      });
  };

  const CompanyList = [
    { name: 'About us', url: '', inPage: false },
    { name: 'press', url: '', inPage: false },
    { name: 'careers', url: '', inPage: false },
    { name: 'ambassadors', url: '', inPage: false },
    { name: 'contact', url: '', inPage: false },
  ];

  const ProductList = [
    { name: 'Savings & Hedging', url: 'product', inPage: true },
    { name: 'Safelock', url: 'product', inPage: true },
    { name: 'Checkouts', url: 'product', inPage: true },
    { name: 'Cards', url: 'product', inPage: true },
    { name: 'Staking & Earning', url: 'product', inPage: true },
  ];

  const ResourcesList = [
    { name: 'customer story', url: '', inPage: false },
    { name: 'media', url: '', inPage: false },
    { name: 'FAQs', url: 'faq', inPage: true },
    { name: 'security', url: '', inPage: false },
    { name: 'Learn', url: '', inPage: false },
    { name: 'help center', url: '', inPage: false },
  ];

  return (
    <Container className='footer'>
      <div className='top--footer'>
        <div className='footer--sub'>
          <Logo />

          <div className='social--container'>
            <a target='blank' href='https://www.linkedin.com/in/3koloapp/'>
              <img src={linkedin} alt='facebook' />
            </a>
            <a target='blank' href='https://www.facebook.com/3KoloApp'>
              <img src={facebook} alt='facebook' />
            </a>
            <a
              target='blank'
              href='https://instagram.com/3koloapp?igshid=YmMyMTA2M2Y='
            >
              <img src={instagram} alt='instagram' />
            </a>
            <a target='blank' href='https://twitter.com/3koloApp'>
              <img src={twitter} alt='twitter' />
            </a>
            L
          </div>

          <div className='sub--container'>
            <p>Subscribe to our weekly newsletter</p>
            <form onSubmit={handleOnSubmit} className='form--container'>
              <input
                id='email'
                type='email'
                name='_replyto'
                onChange={handleOnChange}
                required
                value={inputs.email}
                placeholder='Email Address'
              />
              <PrimaryButton
                btnType='submit'
                disabled={status.submitting}
                type={'primary'}
                text={
                  !status.submitting
                    ? !status.submitted
                      ? 'Subscribe'
                      : 'Submitted'
                    : 'Submitting...'
                }
              />
            </form>
            {status.info.error && (
              <p className='error'>Error: {status.info.msg}</p>
            )}
            {!status.info.error && status.info.msg && <p>{status.info.msg}</p>}
          </div>
        </div>
        <div className='footer--links'>
          <FooterLinks title='COMPANY' linkList={CompanyList} />
          <FooterLinks title='PRODUCTS' linkList={ProductList} />
          <FooterLinks title='RESOURCES' linkList={ResourcesList} />
        </div>
      </div>

      <FooterLowerLinks />
    </Container>
  );
};

export default Footer;
