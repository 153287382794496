import React from "react";

const FooterLowerLinks = () => {
  const year = new Date().getFullYear();

  return (
    <div className="lower--links">
      <div className="links--list">
        <p className="links--list--item">Terms of Use</p>
        <p className="links--list--item">Privacy Policy</p>
        <p className="links--list--item">Risk Warning</p>
        <p className="links--list--item">AML & KYC Policy</p>
        <p className="links--list--item">Cookie Policy</p>
      </div>

      <p className="rights">©{year} 3Kolo Inc. All rights reserved.</p>
    </div>
  );
};

export default FooterLowerLinks;
