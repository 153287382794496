import React from "react";

interface Props {
  title: string;
  text: string;
}

const Feature = ({ title, text }: Props) => {
  return (
    <div className="feature">
      <div className="box"></div>
      <div className="feature--details">
        <h4>{title}</h4>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default Feature;
