import React from 'react'
import WaitListButton from '../WaitListButton/WaitListButton';
import { product } from '../../assets/images';

const ProductBox = () => {
  return (
    <div className="product--box">
      <div className="product--box--text">
        <h4>
          Currency Hedging <br /> & Savings
        </h4>

        <p className="box--info">
          Living and working in an economy with a rapidly devaluing currency is
          not only frustrating but takes a lot of value out of life. 3Kolo is
          your go-to non-custodial wallet that helps you save your local
          currency in top stable-coins while hedging your money against
          devaluation.
        </p>

        {/* <PrimaryButton type={"secondary"} text={"Learn more"} /> */}
        <WaitListButton text={"Coming Soon!"} icon={false} />
      </div>

      <img src={product} alt="product-img" />
    </div>
  );
}

export default ProductBox