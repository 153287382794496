import React from "react";
import FAQuestion from "../FAQuestion/FAQuestion";
import { Container } from "react-bootstrap";

const FAQs = () => {
  return (
    <div className="FAQs" id="faq">
      <Container>
        <div className="faq--title">
          <h1>
            Frequently Asked <br /> Questions?
          </h1>
          <p>
            Have a question that is not answered? You can contact us at{" "}
            <a target="blank" href="mailto:support@3kolo.com">
              support@3kolo.com
            </a>
          </p>
        </div>
        <div className="questions">
          <FAQuestion
            question="What is 3Kolo?"
            answer="3Kolo is a piggybank domiciled on different blockchains. It is your personal savings box that also helps you keep your local currency incomes against devaluation."
          />
          <FAQuestion
            question="What do I need to apply for a personal wallet (Kolo)?"
            answer="You need to complete your registration and generate your personal wallet right on the spot. It is that simple."
          />
          <FAQuestion
            question="How fast is onboarding?"
            answer="Onboarding can be done between 1-3 minutes, subject to your internet speed."
          />
          <FAQuestion
            question="Who are your wallet providers?"
            answer="Our non-custodial wallets are domiciled on Tron, Stellar, Cardano, Binance Smart Chain, and more to come."
          />
          <FAQuestion
            question="What blockchains do you support?"
            answer="Tron, Stellar, Cardano, Binance Smart Chain, and more to come."
          />
          <FAQuestion
            question=" Can I withdraw my money anytime?"
            answer="Yes, you have 247 access to your money. But remember, this is your savings box; don’t break it anyhow."
          />
          <FAQuestion
            question="What are your deposit and withdrawal fees?"
            answer="We charge 0.75% fee on all deposit and withdrawal actions."
          />
        </div>
      </Container>
    </div>
  );
};
export default FAQs;
