import { useState } from "react";
import Logo from "../Logo/Logo";
import { close } from "../../assets/images";

import WaitListSocial from "../WaitListSocial/WaitListSocial";
import WaitListButton from "../WaitListButton/WaitListButton";
import SuccessSubmission from "../SuccessSubmission/SuccessSubmission";

import { BiUser } from "react-icons/bi";
import { HiOutlineMail } from "react-icons/hi";

import * as Yup from "yup";
import { Form, Formik, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { _JOIN_WAITLIST } from "../../redux/waitlist/waitlist-action";

interface Props {
  modal: boolean;
  handleClick: () => void;
}

const WaitListModal = ({ modal, handleClick }: Props) => {
  const dispatch: any = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const error = useSelector((state: any) => state.waitlist.errorMessage);
  const success = useSelector((state: any) => state.waitlist.successMessage);

  return (
    <>
      {modal && (
        <div className="wait--list--modal">
          <div className="modal">
            <div className="header">
              <div onClick={handleClick} className="img--container">
                <img src={close} alt="close" />
              </div>
              <Logo />
            </div>

            <div className="modal--content">
              <div className="text--container">
                <h4>Get early access!</h4>
                <p className="wait--list--info">
                  Join our waitlist and community to be among the first to know
                  when we launch our product. 🚀
                </p>
              </div>
              {success ? (
                <SuccessSubmission success={success} />
              ) : error ? (
                <SuccessSubmission error={error} />
              ) : (
                <Formik
                  key="wait-list"
                  initialValues={{
                    name: "",
                    email: "",
                  }}
                  validationSchema={Yup.object({
                    email: Yup.string()
                      .email("Invalid email address")
                      .required("Email is required"),
                    name: Yup.string().required("Name is required"),
                  })}
                  onSubmit={(values, { setErrors }) => {
                    let errors = {};
                    if (
                      Object.entries(errors).length === 0 &&
                      errors.constructor === Object
                    ) {
                      dispatch(_JOIN_WAITLIST({ values, setLoading }));
                      setLoading(true);
                    } else {
                      setErrors(errors);
                    }
                  }}
                >
                  {({ handleSubmit, touched, errors }) => (
                    <Form onSubmit={handleSubmit} className="form--control">
                      <div className="field--wrapper">
                        <Field
                          className="input--field"
                          name="name"
                          placeholder="Full name"
                          disabled={loading}
                        />
                        <BiUser className="icon" />
                        {touched.name && errors.name && (
                          <p className="error--text">{errors.name}</p>
                        )}
                      </div>

                      <div className="field--wrapper">
                        <Field
                          className="input--field"
                          name="email"
                          placeholder="Email address"
                          disabled={loading}
                        />
                        <HiOutlineMail className="icon" />
                        {touched.email && errors.email && (
                          <p className="error--text">{errors.email}</p>
                        )}
                      </div>
                      <WaitListButton
                        text={"Join the waitlist"}
                        icon={true}
                        btnType="submit"
                        iconType2={true}
                        loadingState={loading}
                      />
                    </Form>
                  )}
                </Formik>
              )}

              <WaitListSocial />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WaitListModal;
