import axios from "axios";
import { BASE_URL } from "../../utils";
import { ERROR, JOIN_WAITLIST, SUCCESS } from "./waitlist-types";

interface WAITLISTPROPS {
  values: {
    name: string;
    email: string;
  };
  setLoading: (value: boolean) => void;
}

export const _JOIN_WAITLIST =
  ({ values, setLoading }: WAITLISTPROPS) =>
  async (dispatch: any) => {
    const { email } = values;

    try {
      const res = await axios.post(
        `${BASE_URL}/extras/subscribe/waitlist`,
        values
      );

      dispatch({
        type: JOIN_WAITLIST,
        payload: res.data.message,
      });

      setLoading(false);

      dispatch({
        type: SUCCESS,
        payload: email,
      });
    } catch (err: any) {
      const error = err.response.data.message;

      dispatch({
        type: ERROR,
        payload: error,
      });

      setLoading(false);
    }
  };
