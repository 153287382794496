import React from "react";
import { hero } from "../../assets/images";
// import ScanBox from "../ScanBox/ScanBox";
import WaitListButton from "../WaitListButton/WaitListButton";
import { WaitListModalProps } from "../NavBar/NavBar";
import { Container } from "react-bootstrap";

const Hero = ({ handleClick }: WaitListModalProps) => {
  return (
    <div className="hero">
      <Container>
        <div className="hero--text">
          <h1 className="hero--title">
            Web3’s first <br /> Piggybank for <br /> Local Currencies
          </h1>
          <h6>
            3Kolo is a Web3 piggybank to hedge your local currency against
            devaluation by saving in dollar stable-coins in personal wallets.
          </h6>
          {/* <ScanBox /> */}
          <WaitListButton
            handleClick={handleClick}
            text="Join Waitlist for early access"
            icon={true}
          />
        </div>
        <div className="hero--img">
          <img src={hero} alt="hero-img" />
        </div>
      </Container>
    </div>
  );
};

export default Hero;
