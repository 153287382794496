import React from "react";

interface Props {
  icon: string;
  title: string;
  text: string;
}

const ComingSoon = ({ icon, title, text }: Props) => {
  return (
    <div className="coming--soon">
      <div className="img-container">
        <img src={icon} alt="coming--soon-icon" />
      </div>
      <div className="coming--text--box">
        <h4>{title}</h4>
        <p>{text}</p>
        <h5>Coming soon!</h5>
      </div>
    </div>
  );
};

export default ComingSoon;
