import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ComingSoon from "../ComingSoon/ComingSoon";
import { cryptoCard, cryptoCheckout, feature, safelock, staking } from "../../assets/images";

const CustomSlider = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow:
      window.innerWidth > 1040
        ? 4
        : window.innerWidth > 912
        ? 3.5
        : window.innerWidth > 768
        ? 2.7 
        : window.innerWidth < 540
        ? 1.7
        : window.innerWidth < 400
        ? 1
        : 2.5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 15000,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnFocus: true,
    adaptiveHeight: true,
    pauseOnHover: true,
  };

  return (
    <Slider {...settings} className="coming--soon--box mobile--box">
      <ComingSoon
        icon={cryptoCheckout}
        title={"Web3 & Crypto Checkouts"}
        text="With your wallet in your control, 3Kolo empowers you to carryout payment checkouts on your favourite Web3 and Crypto-backed platforms."
      />

      <ComingSoon
        icon={safelock}
        title={"Rainy Day Safelock"}
        text="Saving for a rainy day requires a high level of discipline. 3Kolo offers you features like Safelock to help you achieve your saving goals."
      />

      <ComingSoon
        icon={cryptoCard}
        title={"Crypto cards"}
        text="What more explains living a borderless life like spending your crypto savings directly from your 3Kolo card? It’s your savings; spend it your way with no barrier."
      />

      <ComingSoon
        icon={staking}
        title={"Earning & Staking"}
        text="3Kolo gives you access to carefully-verified Web3 staking protocols to help you earn even while you are saving in your non-custodial wallet."
      />

      <ComingSoon
        icon={feature}
        title="Auto-Save"
        text="3Kolo helps you through a direct debit of a set amount from your traditional bank to fund your 3Kolo wallet, thereby helping you auto-save and auto-hedge your money."
      />
    </Slider>
  );
};
export default CustomSlider;
