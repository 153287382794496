import React from "react";
import WaitListButton from "../WaitListButton/WaitListButton";
import { Link } from "react-scroll";

interface Props {
  handleClick: () => void;
  toggleModal?: () => void;
}

const NavList = ({ handleClick, toggleModal }: Props) => {
  return (
    <>
      <div className="primary--nav">
        <ul>
          <Link
            to="product"
            smooth={true}
            offset={40}
            duration={500}
            onClick={toggleModal && toggleModal}
          >
            <li>Product</li>
          </Link>
          <li>Company</li>
          <li>Learn</li>
          <li>Contact Us</li>
        </ul>
      </div>

      <div className="secondary--nav">
        {/* <PrimaryButton type={"secondary"} text={"Login"} />
        <PrimaryButton type={"primary"} text={"Sign up for free"} /> */}
        <WaitListButton
          icon={true}
          text="Join Waitlist"
          handleClick={handleClick}
          style={{ background: "transparent" }}
        />
      </div>
    </>
  );
};

export default NavList;
