import React from "react";
import CustomSlider from "../CustomSlider/CustomSlider";
import ProductBox from "../ProductBox/ProductBox";
import { Container } from "reactstrap";

const Product = () => {
  return (
    <div  className="product" id="products">
      <Container>
        <ProductBox />
        <CustomSlider />
      </Container>
    </div>
  );
};

export default Product;
