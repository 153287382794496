import React from "react";
import ReactLoading from "react-loading";

const LoadingComponent = () => {
  return (
    <ReactLoading
      type={"spin"}
      color={"#ffce00"}
      height={"7%"}
      width={"7%"}
      className="loader"
    />
  );
};

export default LoadingComponent;
