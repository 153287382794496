import React from "react";
import ScanBox from "../ScanBox/ScanBox";
import { Container } from "react-bootstrap";

const CTA = () => {
  return (
    <div className="call--to--action">
      <Container>
        <div className="cta--container">
          <div className="cta--text">
            <h1>Sign up for free</h1>
            <p>
              Get access to the first Web3 Piggybank that gives you multiple
              features in the crypto economy. Download the 3Kolo app and
              experience true personal savings and hedging for your local
              currency.
            </p>
          </div>

          <ScanBox />
        </div>
      </Container>
    </div>
  );
};

export default CTA;
