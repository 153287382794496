import { ERROR, SUCCESS } from "./waitlist-types";

const initialState = {
  successMessage: null,
  errorMessage: null,
};

const WaitListReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case SUCCESS:
      return {
        ...state,
        successMessage: payload,
      };
    case ERROR:
      return {
        ...state,
        errorMessage: payload,
      };

    default:
      return state;
  }
};

export default WaitListReducer;
