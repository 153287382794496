import React, { useEffect, useState } from "react";
import NavBar from "./components/NavBar/NavBar";
import Hero from "./components/Hero/Hero";
import Product from "./components/Product/Product";
import Features from "./components/Features/Features";
import FAQs from "./components/FAQs/FAQs";
import CTA from "./components/CTA/CTA";
import Footer from "./components/Footer/Footer";
import WaitListModal from "./components/WaitListModal/WaitListModal";

function App() {
  const [modal, setModal] = useState<boolean>(false);
  const [waitListModal, setWaitListModal] = useState<boolean>(false);

  modal || waitListModal
    ? (document.body.style.overflow = "hidden")
    : (document.body.style.overflow = "auto");

  const handleClick = () => setWaitListModal(!waitListModal);

  useEffect(() => {
    document.title = "Web3 Piggybank for Hedging Local Currencies";
  }, []);

  return (
    <div className="app">
      <NavBar handleClick={handleClick} modal={modal} setModal={setModal} />
      <Hero handleClick={handleClick} />
      <Product />
      <Features />
      <FAQs />
      <CTA />
      <Footer />
      <WaitListModal modal={waitListModal} handleClick={handleClick} />
    </div>
  );
}

export default App;
