import React, { Fragment } from "react";
import { Link } from "react-scroll";

interface Props {
  title: string;
  linkList: any;
}

const FooterLinks = ({ title, linkList }: Props) => {
  return (
    <div className="link--row">
      <p className="title">{title}</p>
      {linkList.map((obj: any, i: any) => (
        <Fragment key={i}>
          {obj.inPage ? (
            <Link to={obj.url} smooth={true} offset={40} duration={500}>
              <p className="list--item">{obj.name}</p>
            </Link>
          ) : (
            <p className="list--item">{obj.name}</p>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default FooterLinks;
