import React from "react";

interface Props {
  type: string;
  text: string;
  btnType?: any;
  disabled?: boolean;
}

const PrimaryButton = ({ type, text, btnType, disabled }: Props) => {
  return (
    <>
      {type === "primary" ? (
        <button
          type={btnType ? btnType : "button"}
          disabled={disabled}
          className="primary--button"
        >
          <p>{text}</p>
        </button>
      ) : (
        <button
          type={btnType ? btnType : "button"}
          disabled={disabled}
          className="secondary--button"
        >
          <p>{text}</p>
        </button>
      )}
    </>
  );
};

export default PrimaryButton;
