import React from "react";
import { border, mLinkedin } from "../../assets/images";

import { BsTwitter } from "react-icons/bs";

const WaitListSocial = () => {
  return (
    <div className="wait--list--social">
      <div className="social">
        <a target="blank" href="https://twitter.com/3koloApp">
          <BsTwitter className="icon" />
        </a>
        <div className="social--detail">
          <p>Twitter</p>
          <h6>
            <a target="blank" href="https://twitter.com/3koloApp">
              @3Koloapp
            </a>
          </h6>
        </div>
      </div>

      <img src={border} alt="border" />
      <div className="social">
        <a target="blank" href="https://www.linkedin.com/in/3koloapp/">
          <img src={mLinkedin} alt="twitter" className="icon" />
        </a>
        <div className="social--detail">
          <p>LinkedIn</p>
          <h6>
            <a target="blank" href="https://www.linkedin.com/in/3koloapp/">
              @3Koloapp
            </a>
          </h6>
        </div>
      </div>
    </div>
  );
};

export default WaitListSocial;
