import React from "react";
import { qr } from "../../assets/images";

const ScanBox = () => {
  return (
    <div className="scan--box">
      <p>
        Scan to Download <br /> 3Kolo
      </p>
      <div className="img--container">
        <img src={qr} alt="qr-code" />
        <div className="box"></div>
      </div>
    </div>
  );
};

export default ScanBox;
