import React from "react";
import Feature from "../Feature/Feature";
import { business } from "../../assets/images";
import { Container } from "reactstrap";

const Features = () => {
  return (
    <div className="features">
      <Container>
        <h1>
          Keeping your money <br /> safe is our priority.
        </h1>
        <div className="features--list">
          <div className="list">
            <Feature
              title={"Top-Notch Security"}
              text={
                "We use leading industry-standard encryption technology to protect user data. We also use two-factor authentication to verify all customer accounts, so be rest assured that you and your funds are safe with us."
              }
            />
            <Feature
              title={"Assets Custody"}
              text="We are working with the most secure blockchains for the provision of non-custodial wallets to help you secure your assets and give you 247 access to your personal wallets."
            />
            <Feature
              title="Anti-Money Laundering"
              text="Our Web3 security and AML partners are using world-class systems to guard against money laundering activities on 3Kolo. It is a priority for us to keep our platform from bad players."
            />
          </div>
          <img src={business} alt="" />
        </div>
      </Container>
    </div>
  );
};

export default Features;
